// @flow
import React from 'react';
import { Box } from '@material-ui/core';
import { navigate } from 'gatsby';
import Layout from '../../../components/Layout/Layout';
import Hero from '../../../components/Merchant/shared/Hero';
import bg from '../../../images/materials.svg';

const Materials = () => {
  if (typeof window !== `undefined`) {
    navigate('/merchant/materials/sales/');
  }

  return (
    <Layout isMerchant>
      <Box minHeight="100vh">
        <Hero title="資料請求" bg={bg} />
      </Box>
    </Layout>
  );
};

// $FlowFixMe
export default Materials;
